import  { useContext, createContext } from 'react';
import { Student } from '../Class/Student';
import { Aboriginal, Disability, StudentPlanData } from '../Class/StudentPlan';

type StudentPlanContextType = {
    studentPlanData: StudentPlanData;
    studentPublicId: string;
    setStudentPlanData: ( disabilityData?: Disability, aboriginalData?: Aboriginal ) => void;
    student: Student;
}

export const StudentPlanContext = createContext<StudentPlanContextType>({ 
    studentPlanData: new StudentPlanData(),
    studentPublicId: '',
    setStudentPlanData: () => {}, 
    student: new Student()
});

export const useStudentPlan = () => useContext(StudentPlanContext);
