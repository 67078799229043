import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { LookupListDisability } from "../Class/NccdDisabilityLegacy";
import { notification } from "antd";
import { useEffect } from "react";

export const useFetchLookupListDisability = (setLookupDisabilityData: React.Dispatch<React.SetStateAction<LookupListDisability[]>>) => {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchFetchLookupListDisability = async () => {
        if(error){ return new Array<LookupListDisability>(); }
        try {
            const endpoint = `nccd/lookup_disabilities`;
            const { data } = await axios.get<LookupListDisability[]>(endpoint);
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            notification.error({ message: 'Error fetching lookup list disabilities data!', placement: 'topRight' });
            return new Array<LookupListDisability>();
        }
    };

    
    const lookupDisabilitesQuery = useQuery('nccdFetchLookupListDisability', fetchFetchLookupListDisability, {
            staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
            cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
            retry: 1,
            refetchOnMount: false,
            refetchOnWindowFocus: false
        });

    useEffect(() => {
        if(!lookupDisabilitesQuery.isLoading){
            if(lookupDisabilitesQuery.data){
                setLookupDisabilityData(lookupDisabilitesQuery.data);
            }
        }
    }, [lookupDisabilitesQuery.data, lookupDisabilitesQuery, setLookupDisabilityData]);

    return {isLookupDisabilitesLoading: lookupDisabilitesQuery.isLoading};
}