import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { NccdDisabilityLegacy } from "../Class/NccdDisabilityLegacy";
import { useEffect } from "react";
import { notification } from "antd";

export const useFetchNccdDisability = (studentPublicId: string, setNccdDisabilityDataLegacy: (data: NccdDisabilityLegacy[])=>void)=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchNccdDisability = async () => {
        if(error){ return new Array<NccdDisabilityLegacy>(); }
        try {
            const endpoint = `nccd/ppStudentDisability/${studentPublicId}`;
            const { data } = await axios.get<NccdDisabilityLegacy[]>(endpoint);
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            notification.error({ message: 'Error fetching nccd disability data!', placement: 'topRight' });
            return new Array<NccdDisabilityLegacy>();
        }
    };
    
    const nccdDisabilityQuery = useQuery<NccdDisabilityLegacy[]>('nccdDisability', fetchNccdDisability, {
        staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
        cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
        retry: 1,
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(!nccdDisabilityQuery.isLoading){
            if(nccdDisabilityQuery.data){
                setNccdDisabilityDataLegacy(nccdDisabilityQuery.data);
            }
        }
    }, [nccdDisabilityQuery.data, nccdDisabilityQuery.isLoading, setNccdDisabilityDataLegacy]);

    return {isLoading: nccdDisabilityQuery.isLoading};
}