export class NccdDisabilityLegacy {
    studentNccddisabilityId: number;
    nccddisabilityId: number;
    disabilityOrder: boolean;
    otherDisabilityName: string;
    studentId: number;
    studentPublicId: string;
    imputedDescription: string;

    constructor(
        studentNccddisabilityId: number = 0,
        nccddisabilityId: number = 0,
        disabilityOrder: boolean = false,
        otherDisabilityName: string = '',
        studentId: number = 0,
        studentPublicId: string = '',
        imputedDescription: string = '',
    ) {
        this.studentNccddisabilityId = studentNccddisabilityId;
        this.nccddisabilityId = nccddisabilityId;
        this.disabilityOrder = disabilityOrder;
        this.otherDisabilityName = otherDisabilityName;
        this.studentId = studentId;
        this.studentPublicId = studentPublicId;
        this.imputedDescription = imputedDescription;
    }
}

export class LookupListDisabilityCategory {
    public categoryId: number;
    public categoryName: string;

    constructor(
        categoryId: number,
        categoryName: string
    ) {
        this.categoryId = categoryId;
        this.categoryName = categoryName;
    }
}

export class LookupListDisability {
    public nccdDisabilityId: number;
    public disabilityName: string;
    public categoryId: number;

    constructor(
        nccdDisabilityId: number = 0,
        disabilityName: string = '',
        categoryId: number = 0
    ) {
        this.nccdDisabilityId = nccdDisabilityId;
        this.disabilityName = disabilityName;
        this.categoryId = categoryId;
    }
}

export class LookupListDisabilityType {
    public typeId: number;
    public typeName: string;

    constructor(
        typeId: number,
        typeName: string
    ) {
        this.typeId = typeId;
        this.typeName = typeName;
    }
}

export class LookupListDisabilityPriority {
    public priorityOrder: number;
    public priorityName: string;

    constructor(
        priorityOrder: number,
        priorityName: string
    ) {
        this.priorityOrder = priorityOrder;
        this.priorityName = priorityName;
    }
}

