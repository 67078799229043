    import React, { createContext, useContext } from "react";

interface RefsContextType {
    planningMeetingRef: React.RefObject<HTMLDivElement>;
    supportPersonnelRef: React.RefObject<HTMLDivElement>;
    transitionRef: React.RefObject<HTMLDivElement>;
    studentStrengthRef: React.RefObject<HTMLDivElement>;
    externalEviRef: React.RefObject<HTMLDivElement>;
    internalEviRef: React.RefObject<HTMLDivElement>;
    recordDisRef: React.RefObject<HTMLDivElement>;
    functionalRef: React.RefObject<HTMLDivElement>;
    additionalPlansRef: React.RefObject<HTMLDivElement>;
    naplanRef: React.RefObject<HTMLDivElement>;
    goalsRef: React.RefObject<HTMLDivElement>;
    professionalRef: React.RefObject<HTMLDivElement>;
    curriculumnRef: React.RefObject<HTMLDivElement>;
    communicaitonRef: React.RefObject<HTMLDivElement>;
    environmentalRef: React.RefObject<HTMLDivElement>;
    movementRef: React.RefObject<HTMLDivElement>;
    personalRef: React.RefObject<HTMLDivElement>;
    safetyRef: React.RefObject<HTMLDivElement>;
    socialRef: React.RefObject<HTMLDivElement>;
    reviewMeetingRef: React.RefObject<HTMLDivElement>;
    evidenceRef: React.RefObject<HTMLDivElement>;
    goalsMonitoringRef: React.RefObject<HTMLDivElement>;
}

export const RefsContext = createContext<RefsContextType | null>(null);

export const useRefs = () => {
    const context = useContext(RefsContext);
    if (!context) {
        throw new Error("useRefs must be used within a RefsProvider");
    }
    return context;
}
