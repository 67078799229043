import { useContext, useState } from "react";
import { ErrorHandlingContext } from "../Context/ErrorHandlingContext";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "../Enums/ErrorMsg";
import { notification } from "antd";


export const ErrorHandlingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [error, setErrorState] = useState(null);
    const navigate = useNavigate();

    const setError = async (errorObj: any) => {
        if(!errorObj){ return; }
        console.log('error', errorObj);
        if((errorObj.response && (errorObj.response.status === 401 || errorObj.response.status === 403)) || errorObj.message === ErrorMsg.Unauthorised){
            navigate('/unauthorized');
        }
        if(errorObj.message === ErrorMsg.NetworkError || (errorObj.response && errorObj.response.status === 500)){
            navigate('/internal-server-error');
        }
        if(errorObj.response && errorObj.response.data && errorObj.response.data.exceptionDetails && errorObj.response.data.exceptionDetails[0].message === ErrorMsg.StudentNotFound){
            navigate('/student-not-found');
        }
        if(errorObj.response && errorObj.response.status === 404){
            if(errorObj.response.data.detail === ErrorMsg.PlanNotFound){
                notification.warning({key: ErrorMsg.PlanNotFound, message: 'Existing plan not found, will continue with empty plan!', placement: 'topRight', duration: 0 });
                return;
            }
            if(errorObj.message === ErrorMsg.InvalidPublicId){
                notification.error({key:ErrorMsg.InvalidPublicId, message: errorObj.message, placement: 'topRight' });
                navigate('/404');
            }
        }
        setErrorState(error);
    };

    return (
        <ErrorHandlingContext.Provider value={{ error, setError }}>
            {children}
        </ErrorHandlingContext.Provider>
    );
};

export const useErrorHandling = () => {
    const context = useContext(ErrorHandlingContext);
    if (!context) {
        throw new Error('useErrorHandling must be used within an ErrorHandlingProvider');
    }
    return context;
};