enum LookupValue {
    PlanningMeetingHistoric = '58974d82-0a44-40dc-bf29-7ec76841af40',
    PlanningMeetingPurposeOther = '32b39ff0-a24c-4796-9ec2-7b6f0cad66c4',
    PlanningMeetingPurposeMonitoring = 'd2a1d12f-ba4d-4549-9110-38ad1492e0c2',
    PlanningMeetingRolesStudent = 'c4c30ac1-b2a5-4f20-acb7-1ea8eff141c7',
    PlanningMeetingRolesParent = '16214709-8f84-413b-8337-c7262f470a73',
    SupportPersonnelRoleOther = '374044dd-783b-4ba1-8766-15ada2182c25',
    SupportPersonnelIntensityOther = '06462fb9-9b27-40fe-b2fc-84f147b9e070',
    SupportPersonnelFrequencyOther = 'f2a8a5b2-50e5-4615-8eda-a4e28b825357',
    TransitionStageOther = 'f3a1d982-4245-409a-998c-5b73ff761d1c',
    TransitionKeyPeopleOther = 'fffd892d-5887-424d-a224-7af4859585e9',
    TransitionKeyPeopleHistoric = 'efb2732d-7522-4fde-9e7c-a975d95806b4',
    ExternalAssessmentTypeOther = '49200119-ff67-40b7-a670-279cbca25535',
    InternalAssessmentTypeOther = '869891aa-24ff-4ec5-a143-43f79d7fd74f',
    InternalAssessmentTypeHistoric = '936630a8-3c4f-4527-8b7f-674e753fc5b9',
    NaplanSubjectType = '77cca4ce-fff6-4583-997f-27c9effa0bf5',
    NaplanYearType = 'fee83536-c76e-424f-a587-5a5662f5770d',
    NaplanBandType = '7449c79f-3d73-489e-9174-46d749c21f0c',
    NccdDisabilityPrimary = 'a1a4f35c-15db-42d1-971a-420d04d30899',
    NccdDisabilityAdditional = 'c2eb3c67-4300-45e5-a732-da3a678f4f9a',
    NccdDisabilityDiagnosed = '07bd69a0-990f-4668-a0be-0ebac7e7057d',
    NccdDisabilityImputed = '0123620d-f714-4e75-984e-b6ea461d8e24',
    NccdDisabilityCategoryPhysical = 'ba8c4ea1-d7d0-4807-aa84-df0324f6983e',
    NccdDisabilityCategoryCognitive = '7c2477d3-f649-4687-9c10-dd25a07cec17',
    NccdDisabilityCategorySensory = '36470cd5-d010-4ce0-a258-09b1aff920ee',
    NccdDisabilityCategorySocial = '57baa1d2-c4ab-4ec0-86e7-9008258b4787',
    NccdDisabilityCategoryPhysicalOther = '29cfbd0a-ba8c-449c-9b02-3aeda5cbb940',
    NccdDisabilityCategoryCognitiveOther = '6dbe5293-3393-4a0c-87b4-43d2b3b1ca78',
    NccdDisabilityCategorySensoryOther = '9f117ae6-3b34-4e29-9da7-a1180b511dc5',
    NccdDisabilityCategorySocialOther = '33b0034b-bab1-45ff-8c99-04b329d4c143',
    NccdDisabilityImputedlOther = 'a09121a5-cb9c-4c6f-955d-d7ff38fdc072',
    GoalsStatusCurrent = '450dc182-fbd2-4063-9e1d-546d7fe3f42a',
    GoalsStatusAchieved = 'a1d27d68-4fcf-4bfb-8754-020e4e95f596',
    GoalsStatusRevised = '30d5c36f-6097-4bcf-8042-d89f8283772c',
    GoalsStatusNotAchieved = '6b08a678-ef17-4535-8775-791101f40a17',
    ProfessionalAttendeeOther = '4df24c8e-30a3-441e-a45a-d374092c2dbc',
    DeliveryProvisionOther = 'ad85c132-b7f6-4e66-8716-e945446e8973',
    DeliveryLearningOther = 'b3c59a14-daaf-403a-a4cd-ebd733d50a34',
    DeliveryFrequencyOther = 'c154afd0-9c2d-4427-b1bc-b21a1f1c170f',
}

export default LookupValue;