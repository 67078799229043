import React, { useState, useRef, useEffect, ReactNode, MutableRefObject, useCallback } from 'react';

export interface MenuItem {
    key: string;
    label: ReactNode;
}

interface DropdownProps {
    trigger: ReactNode;
    menuItems: MenuItem[];
    className?: string;
    setToggle?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Dropdown: React.FC<DropdownProps> = ({ trigger, menuItems, className = '' , setToggle}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const handleDocumentClick = useCallback((event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setToggle && setToggle(false);
        }
    },[setToggle]);

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
        document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setToggle && setToggle(!isOpen);
    };

    return (
        <div ref={dropdownRef} className={`relative ${className}`}>
        <div onClick={toggleDropdown} className="cursor-pointer">
            {trigger}
        </div>
        {isOpen && (
            <div className="absolute right-0 mt-2 w-40vw bg-white border border-gray-200 rounded-md shadow-lg z-50 overflow-x-auto">
                {menuItems.map((item) => (
                    <div key={item.key} className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer whitespace-nowrap" >
                        {item.label}
                    </div>
                ))}
            </div>
        )}
        </div>
    );
};
