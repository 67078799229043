import { Button, Grid } from "antd";
import { FormModal, handleCancel } from "../../Controls/FormModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faXmark } from "@fortawesome/free-solid-svg-icons";
import { ReportSummaryFormType } from "./useReportSummaryForm";
import { ChangeEvent, FormEvent } from "react";
import {ReactComponent as FaSpinnerThird} from "../../../Assets/Images/FaSpinnerThird.svg";
import MonthList from "../../../Interface/MonthListType";


interface FullReportFormProps {
    isFullModalVisible: boolean;
    setIsFullModalVisible: (visible: boolean) => void;
    formData: ReportSummaryFormType[];
    setFormData: React.Dispatch<React.SetStateAction<ReportSummaryFormType[]>>;
    handleCheckboxChange: (e: ChangeEvent<HTMLInputElement>) => void;
    isSubmitting: {fullReport: boolean, goalsReport: boolean, summaryReport: boolean};
    initialData: ReportSummaryFormType[];
    title:string;
    handleFullReport: (e: FormEvent<HTMLFormElement>) => void;
    fromDateFilter: string;
    handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    defaultMonthsList: MonthList[];
}



export const FullReportForm: React.FC<FullReportFormProps> = ({ isFullModalVisible, setIsFullModalVisible, setFormData, isSubmitting, initialData, title, handleFullReport, fromDateFilter, handleChange, defaultMonthsList})=> {
    const { useBreakpoint } = Grid;
    const screens = useBreakpoint();
    const onCancel = () => { setIsFullModalVisible(false); setFormData(initialData); }


    return (
        <FormModal isVisible={isFullModalVisible} onClose={()=>handleCancel({ onCancel, hideConfirmation: true})} title={title} className="sm:w-[75%] md:max-w-screen-xl">
            <form className="flex flex-col text-xs sm:text-sm mt-4 shadow-md p-1 sm:p-4" onSubmit={handleFullReport}>
                
                <div className="flex flex-col sm:flex-row sm:mb-0">
                    <label className="font-semibold mr-2 mt-2" htmlFor="reportDate">Date range filter:</label>
                    <select name="fromDateFilter" className="p-1 w-52 border-2 border-gray-400 rounded-md focus:border-blue-400 focus:outline-0" onChange={handleChange}>
                        <option value="">None</option>
                        {defaultMonthsList.map((item) => {
                            return <option key={item.publicId} value={item.value}>{item.displayValue}</option>;
                        })}
                    </select>
                </div>
                
                <div className="justify-center mt-4">
                    <label className="font-semibold" htmlFor="consent">I agree that my activity of accessing the report will be logged.</label>
                    <input className="border-2 mx-2 transform scale-150" type="checkbox" name="consent" required/>
                </div>

                
                <div className="flex justify-between my-4">
                    <Button size={screens.xs ? "small" : "middle"} onClick={()=>handleCancel({ onCancel, hideConfirmation: true})} className="mr-2 p-0 border-2 border-gray-400 bg-white hover:bg-neutral-300 text-black sm:text-lg hover:outline-0">
                        <FontAwesomeIcon icon={faXmark} className="mx-2" />
                        <span className="mr-2">Cancel</span>
                    </Button>
                    <Button size={screens.xs ? "small" : "middle"} htmlType="submit" className="mr-2 p-0 bg-green-600 hover:bg-green-700 text-white sm:text-lg hover:outline-0">
                        <span className="mx-2 flex">Download Report{isSubmitting.fullReport ? <FaSpinnerThird className="mx-2 w-4 animate-spin"/> : <FontAwesomeIcon icon={faDownload} className="mx-2 mt-1"/>}</span>
                    </Button>
                </div>
            </form>
        </FormModal>
    );
}