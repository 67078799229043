const enum SectionRef {
    PlanningMeeting = 'planningMeeting',
    SupportPersonnel = 'supportPersonnel',
    Transition = 'transition',
    StudentStrengths = 'studentStrengths',
    ExternalEvidence = 'externalEvidence',
    InternalEvidence = 'internalEvidence',
    RecordDisability = 'recordDisability',
    FunctionalImpact = 'functionalImpact',
    AdditionalPlanning = 'additionalPlanning',
    Naplan = 'naplan',
    Goals = 'goals',
    ProfessionalLearning = 'professionalLearning',
    Curriculum = 'curriculum',
    Communication = 'communication',
    Environment = 'environment',
    Movement = 'movement',
    Personal = 'personal',
    Safety = 'safety',
    Social = 'social',
    ReviewMeeting = 'reviewMeeting',
    Evidence = 'evidence',
    GoalsMonitoring = 'goalsMonitoring',
}
export default SectionRef;