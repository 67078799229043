export class Student {
    public studentFirstName: string;
    public studentLastName: string;
    public orgnisationName: string;
    public locationSuburb: string;
    public role: string;
    public gradeId: number;
    public gradeDescription: string;
    public studentId: number;

    constructor(
        studentFirstName: string = '',
        studentLastName: string = '',
        orgnisationName: string = '',
        locationSuburb: string = '',
        role: string = '',
        gradeId: number = 0,
        gradeDescription: string = '',
        studentId: number = 0
    ) {
        this.studentFirstName = studentFirstName;
        this.studentLastName = studentLastName;
        this.orgnisationName = orgnisationName;
        this.locationSuburb = locationSuburb;
        this.role = role;
        this.gradeId = gradeId;
        this.gradeDescription = gradeDescription;
        this.studentId = studentId;
    }
}
