import { useRef } from "react";
import { RefsContext } from "../Context/RefsContext";

export const RefsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const planningMeetingRef = useRef<HTMLDivElement>(null);
    const supportPersonnelRef = useRef<HTMLDivElement>(null);
    const transitionRef = useRef<HTMLDivElement>(null);
    const studentStrengthRef = useRef<HTMLDivElement>(null);
    const externalEviRef = useRef<HTMLDivElement>(null);
    const internalEviRef = useRef<HTMLDivElement>(null);
    const recordDisRef = useRef<HTMLDivElement>(null);
    const functionalRef = useRef<HTMLDivElement>(null);
    const additionalPlansRef = useRef<HTMLDivElement>(null);
    const naplanRef = useRef<HTMLDivElement>(null);
    const goalsRef = useRef<HTMLDivElement>(null);
    const professionalRef = useRef<HTMLDivElement>(null);
    const curriculumnRef = useRef<HTMLDivElement>(null);
    const communicaitonRef = useRef<HTMLDivElement>(null);
    const environmentalRef = useRef<HTMLDivElement>(null);
    const movementRef = useRef<HTMLDivElement>(null);
    const personalRef = useRef<HTMLDivElement>(null);
    const safetyRef = useRef<HTMLDivElement>(null);
    const socialRef = useRef<HTMLDivElement>(null);
    const reviewMeetingRef = useRef<HTMLDivElement>(null);
    const evidenceRef = useRef<HTMLDivElement>(null);
    const goalsMonitoringRef = useRef<HTMLDivElement>(null);



    return (
        <RefsContext.Provider value={{ planningMeetingRef, supportPersonnelRef, transitionRef, studentStrengthRef, externalEviRef, internalEviRef, recordDisRef, functionalRef, additionalPlansRef, naplanRef, goalsRef, professionalRef, curriculumnRef, communicaitonRef, environmentalRef, movementRef, personalRef, safetyRef, socialRef, reviewMeetingRef, evidenceRef, goalsMonitoringRef }}>
            {children}
        </RefsContext.Provider>
    );
}