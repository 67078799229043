import { useQuery } from "react-query";
import { Aboriginal, Disability, StudentPlanData } from "../Class/StudentPlan";
import { useEffect } from "react";
import { Student } from "../Class/Student";
import { useAuthAxios } from "./useAuthAxios";
import { isValidUUID } from "../Validations/ValidateGuid";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import React from "react";
import AccessLevelType from "../Enums/AccessLevel";
import PlanType from "../Enums/PlanType";


export const useFetchStudentPlans = (planType: PlanType, studentPublicId: string, setAccessLevel: React.Dispatch<React.SetStateAction<AccessLevelType>>, setStudentPlanData: (disabilityData?: StudentPlanData['disability'], aboriginalData?: StudentPlanData['aboriginal'])=>void, setStudent: React.Dispatch<React.SetStateAction<Student>>) => {
  const {axios} = useAuthAxios();
  const {error, setError } = useErrorHandling();

  const fetchStudent = async (): Promise<Student | null> => {
    if(error){ return new Student(); }
    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return new Student();
    }

    const endpoint = `plans/header?studentPublicId=${studentPublicId}`;
    try{
      const { data: studentData } = await axios.get<Student>(endpoint);
      setError(null);
      return studentData;
    }catch(error){
      setError(error);
      return new Student();
    }
  };

  const fetchAccessLevel = async (): Promise<AccessLevelType> => {
    if(error){ return AccessLevelType.NoAccess; }
    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return AccessLevelType.NoAccess;
    }

    const endpoint = `plans/get-access-level/${studentPublicId}`;
    try{
      const { data: accessLevel } = await axios.get<AccessLevelType>(endpoint);
      setError(null);
      return accessLevel;
    }catch(error){
      setError(error);
      return AccessLevelType.NoAccess;
    }
  };

  const fetchDisabilityPlan = async (): Promise<Disability | null> => {
    if(error){ return new Disability(); }
    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return new Disability();
    }
    
    if(planType !== PlanType.DISABILITY){ return new Disability()};

    const endpoint = `plans/${studentPublicId}/${process.env.REACT_APP_DISABLITY_PLANTYPE_ID}`;
    try{
      const { data: disabilityData } = await axios.get<Disability>(endpoint);
      setError(null);
      return disabilityData;
    }
    catch(error){
      setError(error);
      return new Disability();
    }
  };

  const fetchAboriginalPlan = async (): Promise<Aboriginal | null> => {
    if(error) { return new Aboriginal(); }
    if (!isValidUUID(studentPublicId)) {
      setError({message: 'Invalid Public Id'});
      return new Aboriginal();
    }
    
    if(planType !== PlanType.ABORIGINAL){ return new Aboriginal();}
    
    const endpoint = `plans/${studentPublicId}/${process.env.REACT_APP_ABORIGINAL_PLANTYPE_ID}`;
    try{
      const { data: aboriginalData } = await axios.get<Aboriginal>(endpoint);
      setError(null);
      return aboriginalData;
    }catch(error){
      setError(error);
      return new Aboriginal();
    }
  };


  const studentQuery = useQuery<Student | null>(['fetchStudent', studentPublicId], fetchStudent, {
    staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const accessLevelQuery = useQuery<AccessLevelType>(['fetchAccessLevel', studentPublicId], fetchAccessLevel, {
    staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const disabilityQuery = useQuery<Disability | null>(['fetchDisabilityPlan', studentPublicId], fetchDisabilityPlan, {
    staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const aboriginalQuery = useQuery<Aboriginal | null>(['fetchAboriginalPlan', studentPublicId], fetchAboriginalPlan, {
    staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });



  useEffect(() => {
    if (!disabilityQuery.isLoading && !aboriginalQuery.isLoading && !studentQuery.isLoading && !accessLevelQuery.isLoading) {
      if(studentQuery.data){
        setStudent(studentQuery.data);
      }
      if(disabilityQuery.data){
        setStudentPlanData( disabilityQuery.data );
      }
      if(aboriginalQuery.data){
        setStudentPlanData( undefined, aboriginalQuery.data );
      }
      if(accessLevelQuery.data){
        setAccessLevel(accessLevelQuery.data);
      }
    }
  }, [ disabilityQuery.data, aboriginalQuery.data, setStudentPlanData, disabilityQuery.isLoading, aboriginalQuery.isLoading, studentQuery.isLoading, accessLevelQuery.isLoading, setStudent, studentQuery.data, accessLevelQuery.data]);

  
//   useEffect(() => {
//     connection.on("PlanUpdated", (updatedPlanId: string, modifiedBy: string) => {
//       if (studentPlanPublicId === updatedPlanId) {
//         onUpdate();
//         console.log(`Plan updated by ${modifiedBy}`);
//         localStorage.removeItem(`${PlanType.DISABILITY}_${studentPublicId.toLocaleLowerCase()}`);
//         disabilityQuery.refetch();
//       }
//   });

//   return () => {
//       connection.off("PlanUpdated");
//   };
// }, [studentPlanPublicId]);

  return {
    isDisabilityLoading: disabilityQuery.isLoading,
    isAboriginalLoading: aboriginalQuery.isLoading,
    isStudentLoading: studentQuery.isLoading
  };
};