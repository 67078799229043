const enum ErrorMsg {
    PlanNotFound = 'Student Plan not found',
    NotModified = 'student plan has not been modified (no save required).',
    StudentNotFound = 'Student not found',
    NetworkError = 'Network Error',
    InvalidPublicId = 'Invalid Public Id',
    Unauthorised = 'Request failed with status code 401',
    UnauthorisedStudentAccess = 'Unauthorized access to student',
    SelectedStudentNotFound = 'Selected students not found',
}
export default ErrorMsg;