import React, { useCallback, useState } from "react";
import { StudentPlanData} from "../Class/StudentPlan";
import { Student } from "../Class/Student";
import { StudentPlanContext } from "../Context/StudentPlanContext";
import { Loading } from "../Components/Layout/Loading";
import { useFetchStudentPlans } from "../Hooks/useFetchStudentPlans";
import { useApp } from "../Context/AppContext";

  interface StudentPlanDataProviderProps {
    children: React.ReactNode;
  }

export const StudentPlanProvider: React.FC<StudentPlanDataProviderProps> = ({ children }) => {
    const [studentPlanData, setStudentPlanDataState] = useState<StudentPlanData>(new StudentPlanData());
    const [student, setStudent] = useState<Student>(new Student());
    const {planType, studentPublicId, setAccessLevel} = useApp();

    const setStudentPlanData = useCallback((disabilityData?: Partial<StudentPlanData['disability']>, aboriginalData?: Partial<StudentPlanData["aboriginal"]>) => {
      setStudentPlanDataState(prevState => {
          const newState: StudentPlanData = { ...prevState };
          if (disabilityData) {
              newState.disability = { ...newState.disability, ...disabilityData };
          }
          if (aboriginalData) {
              newState.aboriginal = { ...newState.aboriginal, ...aboriginalData };
          }
          return newState;
      });
  }, []);
  
    const { isDisabilityLoading, isAboriginalLoading, isStudentLoading } = useFetchStudentPlans(planType, studentPublicId, setAccessLevel, setStudentPlanData, setStudent);


    if (isDisabilityLoading || isAboriginalLoading || isStudentLoading) return <Loading />;
  
    return (
      <StudentPlanContext.Provider value={{ studentPlanData, studentPublicId, student, setStudentPlanData }}>
        {children}
      </StudentPlanContext.Provider>
    );
  }; 