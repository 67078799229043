const Aboriginal = () => {
    return (
        <div className="flex justify-center">
            <div className="flex-grow px-2 w-full md:max-w-screen-4xl">
                Aboriginal Home
            </div>
        </div>
    );
}

export default Aboriginal;
