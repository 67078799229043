import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {axiosInstancePP} from '../Api/axiosInstance';
import axios, { AxiosInstance } from 'axios';
import { notification } from 'antd';

interface UseAuthAxios {
  axios: AxiosInstance;
  isAxiosError: typeof axios.isAxiosError;
}

export const useAuthAxios = (): UseAuthAxios => {
  const { getAccessTokenSilently } = useAuth0();

  const axiosInstance = axiosInstancePP;

  useEffect(() => {
    const interceptorId = axiosInstance.interceptors.request.use(async (config) => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        notification.error({ message: 'Error getting access token!', placement: 'topRight' });
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    return () => {
      axiosInstance.interceptors.request.eject(interceptorId);
    };
  }, [getAccessTokenSilently]);

  return {axios: axiosInstance, isAxiosError: axios.isAxiosError};
};
