import { useContext, createContext } from 'react';
import IObjectWithRefId from '../Interface/IObjectWithRefId';
import LookupCheckboxItem from '../Types/LookupCheckboxItem';

interface LookupCheckboxContextType {
    lookupCheckboxData: LookupCheckboxItem[];
    getLookupCheckboxById: <T extends IObjectWithRefId>(item: T) => string;
    getLookupCheckboxsBySectionId: (sectionId: string) => LookupCheckboxItem[];
}

export const LookupCheckboxContext = createContext<LookupCheckboxContextType>({ 
    lookupCheckboxData: new Array<LookupCheckboxItem>(), 
    getLookupCheckboxById: () => "Not Found", 
    getLookupCheckboxsBySectionId: () => []
});

export const useLookupCheckbox = () => useContext(LookupCheckboxContext);
