import React, { useState } from "react";
import { Loading } from "../Components/Layout/Loading";
import { useFetchNaplan } from "../Hooks/useFetchNaplan";
import { NaplanContext } from "../Context/NaplanContext";
import { Naplan } from "../Class/Naplan";
import { useApp } from "../Context/AppContext";

interface NaplanProviderProps {
    children: React.ReactNode;
}


export  const NaplanProvider: React.FC<NaplanProviderProps> = ({children }) => {
    const [naplanData, setNaplanData] = useState<Naplan[]>([]);
    const { studentPublicId } = useApp();
    const { isLoading } = useFetchNaplan(setNaplanData, studentPublicId);

    if (isLoading) return <Loading />;

    return (
        <NaplanContext.Provider value={{ naplanData}}>
            {children}
        </NaplanContext.Provider>
    );
};