import  { useContext, createContext } from 'react';
import LookupListItem from '../Types/LookupListItem';
import IObjectWithRefId from '../Interface/IObjectWithRefId';

interface LookupListContextType {
    lookupListData: LookupListItem[];
    getLookupItemById: <T extends IObjectWithRefId>(item: T) => string;
    getLookupItemDisplayById: (item: string) => string;
    getLookupItemsBySectionId: (sectionId: string) => LookupListItem[];
}

export const LookupListContext = createContext<LookupListContextType>({ 
    lookupListData: new Array<LookupListItem>(), 
    getLookupItemById: () => "Not Found",
    getLookupItemDisplayById: () => "Not Found",
    getLookupItemsBySectionId: () => []
});

export const useLookupList = () => useContext(LookupListContext);
