import { faBackwardFast, faBackwardStep, faChevronDown, faForwardFast, faForwardStep } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {Dropdown, MenuProps } from "antd";
import { SelectedStudent } from "../../../Class/SelectedStudent";

interface StudentPlayerProps {
    multiStudentList: SelectedStudent[];
    setStudentPublicId: (studentPublicId: string) => void;
}

export const StudentPlayer: React.FC<StudentPlayerProps> = ({multiStudentList, setStudentPublicId})=> {
    const [selectedStudent, setSelectedStudent] = useState<SelectedStudent | null>(null);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [isListToggled, setIsListToggled] = useState<boolean>(false);
    const [displayedStudents, setDisplayedStudents] = useState<SelectedStudent[]>([]);

    const updateDisplayedStudents = (index: number) => {
        const leftIndex = (index - 1 + multiStudentList.length) % multiStudentList.length;
        const rightIndex = (index + 1) % multiStudentList.length;

        setDisplayedStudents([
            multiStudentList[leftIndex],
            multiStudentList[index],
            multiStudentList[rightIndex]
        ]);
        localStorage.setItem('multiCurrentStudent', multiStudentList[index].publicId);
        setStudentPublicId(multiStudentList[index].publicId);
    };

    useEffect(() => {
        var prevSelectedStudentPublicId = localStorage.getItem('multiCurrentStudent');
        if(prevSelectedStudentPublicId){
            var index = multiStudentList.findIndex(s => s.publicId === prevSelectedStudentPublicId);
            if(index !== -1){
                setSelectedStudent(multiStudentList[index]);
                setCurrentIndex(index);
                updateDisplayedStudents(index);
            }
        }
        else{
            setSelectedStudent(multiStudentList[0]);
            setCurrentIndex(0);
            updateDisplayedStudents(0);
        }
    }, [multiStudentList, setSelectedStudent]);
    

    const handleStudentSelection = (student: SelectedStudent) => {
        const index = multiStudentList.findIndex(s => s.publicId === student.publicId);
        localStorage.setItem('multiCurrentStudent', student.publicId);
        if (index !== -1) {
            setSelectedStudent(student);
            setCurrentIndex(index);
            updateDisplayedStudents(index);
        }
        if (isListToggled){
            setIsListToggled(false);
        }
    };

    const handleBackwardStep = () => {
        const newIndex = (currentIndex - 1 + multiStudentList.length) % multiStudentList.length;
        setCurrentIndex(newIndex);
        setSelectedStudent(multiStudentList[newIndex]);
        updateDisplayedStudents(newIndex);
    };

    const handleBackwardFast = () => {
        setCurrentIndex(0);
        setSelectedStudent(multiStudentList[0]);
        updateDisplayedStudents(0);
    };

    const handleForwardStep = () => {
        const newIndex = (currentIndex + 1) % multiStudentList.length;
        setCurrentIndex(newIndex);
        setSelectedStudent(multiStudentList[newIndex]);
        updateDisplayedStudents(newIndex);
    };

    const handleForwardFast = () => {
        const lastIndex = multiStudentList.length - 1;
        setCurrentIndex(lastIndex);
        setSelectedStudent(multiStudentList[lastIndex]);
        updateDisplayedStudents(lastIndex);
    };

    const items: MenuProps['items'] = multiStudentList.map((student) => ({
        key: student.publicId.toString(),
        label: <h1 key={student.publicId} className={`px-2 text-md rounded-sm cursor-pointer hover:bg-blue-300 ${ selectedStudent?.publicId === student.publicId ? "bg-green-300" : "" }`} onClick={()=>handleStudentSelection(student)}>{student.firstName}</h1>
    }));
    
    return (
        <div className="flex justify-center items-center border-1.5 border-blue-300 rounded-md shadow-sm mx-2 px-2 my-2 min-h-8 min-w-[550px] max-w-[550px]">
            <FontAwesomeIcon  icon={faBackwardStep} className="mx-1 hover:text-blue-300 hover:scale-125 cursor-pointer" onClick={handleBackwardStep}/>
            <FontAwesomeIcon  icon={faBackwardFast} className="mx-1 px-2 hover:text-blue-300 hover:scale-125 cursor-pointer" onClick={handleBackwardFast}/>
            <div className="flex justify-center min-w-96 max-w-96">
            {displayedStudents.map((student, index) => ( 
                <h1 key={index} className={`px-2 rounded-sm cursor-pointer hover:bg-blue-300 hover:scale-105 truncate ${ selectedStudent?.publicId === student.publicId ? "bg-green-300" : "" }`} onClick={()=>handleStudentSelection(student)}>{student.firstName}</h1> 
                ))}
            </div>
            <FontAwesomeIcon  icon={faForwardStep} className="mx-1 hover:text-blue-300 hover:scale-125 cursor-pointer" onClick={handleForwardStep}/>
            <FontAwesomeIcon  icon={faForwardFast} className="mx-1 px-2 hover:text-blue-300 hover:scale-125 cursor-pointer" onClick={handleForwardFast}/>
            <Dropdown menu={{items}} trigger={['click']}>
                <FontAwesomeIcon  icon={faChevronDown} className={`text-black hover:text-blue-300 hover:scale-125 cursor-pointer `} />
            </Dropdown>
        </div>
    );
}