import  { useContext, createContext } from 'react';
import { Naplan } from '../Class/Naplan';

interface NaplanContextType {
    naplanData: Naplan[];
}

export const NaplanContext = createContext<NaplanContextType>({ 
    naplanData: new Array<Naplan>(), 
});

export const useNaplan = () => useContext(NaplanContext);
