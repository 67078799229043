import { motion } from "framer-motion";
import { SVGProps } from "react"


export const LogoAnimateSvg = (props: SVGProps<SVGSVGElement>) => {
const initialFill: string = "#A0A0A0";

const container = {
    animate:{transition: {
    delayChildren: 1
}} 
};

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width={70}
      height={66}
      fill="none"
      initial="initial"
      animate="animate"
      variants={container}
    >
      <motion.path
      id="first"
        d="M24.567.993C14.33.264 6.571.18 0 1.11v17.94c5.885-2.624 15.864-5.494 29.081-4.76l.037.002c.715-5.144 1.591-9.409 2.48-12.884-2.267-.105-4.61-.243-7.031-.415Z"
        animate={{
          fill:[initialFill, "#FFD700", initialFill],
          transition: {delay: 0.1, duration: 0.3, repeat: Infinity, ease: "easeInOut", repeatDelay: 1.2}
        }}
      />
      <motion.path
      id="second"
        d="M33.939 14.53c14.34.615 24.597.229 30.751-.213C66.923 8.725 68.006 5.026 69.039 0c-8.56 1.324-19.147 2.073-34.35 1.536a281.336 281.336 0 0 0-.75 12.994Z"
        animate={{
          fill:[ initialFill, "#253473", initialFill],
          transition: {delay: 0.3, duration: 0.3, repeat: Infinity, ease: "easeInOut", repeatDelay: 1.2}
        }}
      />
      <motion.path
      id="third"
        d="M63.823 16.471c-5.98 1.624-15.683 3.36-30.035 3.468a404.395 404.395 0 0 0 .021 16.563c.16 7.608-.709 14.227-2.02 19.746a612.599 612.599 0 0 0 19.508 8.796c-.696-7.02-1.095-19.467 6.636-34.82 2.452-4.872 4.186-9.56 5.89-13.753Z"
        animate={{
          fill:[ initialFill, "#3EA9E0", initialFill],
          transition: { delay: 0.6, duration: 0.3, repeat: Infinity, ease: "easeInOut", repeatDelay: 1.2}
      }}
      />
      <motion.path
      id="fourth"
        d="M28.444 19.906c-2.05-.041-4.182-.113-6.401-.222C12.423 19.209 5.685 19.42 0 20.88v23.973c9.106 1.421 16.947 4.005 27.401 9.313.124-3.004.147-6.304.092-9.984-.139-9.445.259-17.491.951-24.276Z"
        animate={{
          fill:[ initialFill, "#C71618", initialFill],
          transition: {delay: 0.9, duration: 0.3, repeat: Infinity, ease: "easeInOut", repeatDelay: 1.2}
        }}
      />
    </motion.svg>
  );
}