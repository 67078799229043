enum LookupCheckbox{
        StudentStrengthSpecificInterests = 'd3bdb72c-362c-42b1-8490-5b649c7c9fa3',
        AdditionalPlansOther = 'b5d263d2-f673-4cc9-88f9-728ae6a1e6ee',
        FunctionalCurriculumAssessment = '4efc75d7-8c7e-4821-a506-22ba7ab72642',
        FunctionalCommunication = '39c26987-30d6-459a-b9e3-5f090d3e22e6',
        FunctionalEnvironmental = 'b642333c-dc2b-4dde-b2b4-a25790e30119',
        FunctionalMobility = '855f6027-ca91-4518-85e3-522eaf1b3fcf',
        FunctionalPersonalCare = 'b7899f62-a9c8-4c1d-a7fa-70026dfea625',
        FunctionalSafety = 'edb0d8d0-c17b-4873-a116-f091e0e437d4',
        FunctionalSocial = '43454b62-ad97-439a-8ae3-595e0f70f698',
        FunctionalOther = '365b715e-31db-4040-84d2-b862c8e271f6'
    }

export default LookupCheckbox;