import  { useContext, createContext } from 'react';
import { LookupListDisability, LookupListDisabilityCategory, LookupListDisabilityPriority, LookupListDisabilityType, NccdDisabilityLegacy } from '../Class/NccdDisabilityLegacy';
import { NccdDisability } from '../Class/StudentPlan';

interface NccdDisabilityContextType {
    nccdDisabilityDataLegacy: NccdDisabilityLegacy[];
    getNccdDisabilityType: (item: number) => string;
    getNccdDisabilityPriority: (item: number) => string;
    getNccdDisabilityCategory: (item: number) => string;
    getNccdDisabilitySpecification: (item: number) => string;
    getNccdDisabilitySpecificationOther: (item: NccdDisabilityLegacy) => string;
    convertToLegacyDisability: (item: NccdDisability, studentId: number) => NccdDisabilityLegacy;
    convertFromLegacyDisability: (item: NccdDisabilityLegacy) => NccdDisability;
    setNccdDisabilityDataLegacy: React.Dispatch<React.SetStateAction<NccdDisabilityLegacy[]>>;
    lookupDisabilityData: LookupListDisability[];
    lookupDisabilityCategory: LookupListDisabilityCategory[];
    lookupDisabilityType: LookupListDisabilityType[];
    lookupDisabilityPriority: LookupListDisabilityPriority[];
}

export const NccdDisabilityContext = createContext<NccdDisabilityContextType>({ 
    nccdDisabilityDataLegacy: new Array<NccdDisabilityLegacy>(),
    getNccdDisabilityType: () => "",
    getNccdDisabilityPriority: () => "",
    getNccdDisabilityCategory: () => "",
    getNccdDisabilitySpecification: () => "",
    getNccdDisabilitySpecificationOther: () => "",
    convertToLegacyDisability: () => new NccdDisabilityLegacy(),
    convertFromLegacyDisability: () => new NccdDisability(),
    setNccdDisabilityDataLegacy: () => {},
    lookupDisabilityData: new Array<LookupListDisability>(),
    lookupDisabilityCategory: new Array<LookupListDisabilityCategory>(),
    lookupDisabilityType: new Array<LookupListDisabilityType>(),
    lookupDisabilityPriority: new Array<LookupListDisabilityPriority>()
});

export const useNccdDisability = () => useContext(NccdDisabilityContext);
