import { ReactNode, useState } from "react";
import { AppContext } from "../Context/AppContext";
import AccessLevelType from "../Enums/AccessLevel";
import PlanType from "../Enums/PlanType";
import { SelectedStudent } from "../Class/SelectedStudent";

interface AppProviderProps {
    children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({children}) => {
    const [studentPublicId, setStudentPublicId] = useState<string>('');
    const [planType, setPlanType] = useState<PlanType>(PlanType.DISABILITY);
    const [multiMode, setMultiMode] = useState<boolean>(false);
    const [accessLevel, setAccessLevel] = useState<AccessLevelType>(AccessLevelType.NoAccess);
    const [multiStudentList, setMultiStudentList] = useState<SelectedStudent[]>([]);

    return (
        <AppContext.Provider value={{ studentPublicId, setStudentPublicId, planType, setPlanType, multiMode, setMultiMode, accessLevel, setAccessLevel, multiStudentList, setMultiStudentList }}>
            {children}
        </AppContext.Provider>
    );
};