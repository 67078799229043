import LookupListItem from "../Types/LookupListItem";
import React, { useState } from "react";
import IObjectWithRefId from "../Interface/IObjectWithRefId";
import { LookupListContext } from "../Context/LookupListContext";
import { Loading } from "../Components/Layout/Loading";
import { useFetchLookupList } from "../Hooks/useFetchLookupList";


export  const LookupListProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lookupListData, setLookupListData] = useState<LookupListItem[]>([]);
    const { isLoading } = useFetchLookupList(setLookupListData);
    
    const getLookupItemById = <T extends IObjectWithRefId>(item: T): string => {
      const result = lookupListData?.find((c: LookupListItem) => c.publicId.toLowerCase() === item.refId.toLowerCase());
      return result ? (result.hasOther && item.other ? item.other : result.displayValue) : "Not Found";
    };

    const getLookupItemDisplayById = (item:string): string =>{
        const result = lookupListData?.find((c: LookupListItem) => c.publicId.toLowerCase() === item.toLowerCase());
        return result ? result.displayValue : "Not Found";
    }

    const getLookupItemsBySectionId = (sectionId: string): LookupListItem[] => {
      let list = lookupListData.filter((c: LookupListItem) => c.sectionPublicId.toLowerCase() === sectionId.toLowerCase() && !c.archived);
      list.sort((a, b) => a.displayOrder - b.displayOrder);
      return list;
    }
  
    if (isLoading) return <Loading />;
  
    return (
      <LookupListContext.Provider value={{ lookupListData, getLookupItemById, getLookupItemDisplayById, getLookupItemsBySectionId}}>
        {children}
      </LookupListContext.Provider>
    );
  };