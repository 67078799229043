import * as Yup from 'yup';
import LookupCheckbox from '../Enums/LookupCheckbox';

export const DisabilitySchema = Yup.object({
    studentStrengths: Yup.object({
        studentInterests: Yup.array().of(
            Yup.object({
                refId: Yup.string().required('Reference ID is required'),
                other: Yup.string().when("refId", (refId, schema) => 
                refId.toString().toLowerCase() === LookupCheckbox.StudentStrengthSpecificInterests
                ? schema.required('Details for the Specific Interests are required!')
                : schema.notRequired()
            )
            })
        )
    }),
    currentAdditionalPlans: Yup.array().of(
        Yup.object({
            refId: Yup.string().required('Reference ID is required'),
            other: Yup.string().when("refId", (refId, schema) => 
            refId.toString().toLowerCase() === LookupCheckbox.AdditionalPlansOther
            ? schema.required('Details for the other are required!')
            : schema.notRequired()
        )
        }))
}
);


export const handleYupError = (err: Yup.ValidationError) => {
    const studentInterestErrors: string[] = [];
    const currentAdditionalPlansErrors: string[] = [];
    const newErrors = err.inner.reduce((acc: { [key: string]: string | string[] }, curr: Yup.ValidationError) => {
        const path = curr.path || '';
        if (path.startsWith('studentStrengths.studentInterests')) {
            studentInterestErrors.push(curr.message);
            } else if (path.startsWith('currentAdditionalPlans')) {
                currentAdditionalPlansErrors.push(curr.message);
            }
            else {
                const normalizedPath = path.replace(/\[\d+\]\./, '');
                acc[normalizedPath] = curr.message;
            }
    return acc;
    }, {});

    if (studentInterestErrors.length > 0) {
        newErrors.studentInterests = studentInterestErrors.join(', ');
    }

    if (currentAdditionalPlansErrors.length > 0) {
    newErrors.currentAdditionalPlans = currentAdditionalPlansErrors.join(', ');
    }
    return newErrors;
};