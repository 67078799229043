import { useQuery } from "react-query";
import { useAuthAxios } from "./useAuthAxios";
import { useErrorHandling } from "../Provider/ErrorHandlingProvider";
import { LookupListDisabilityCategory } from "../Class/NccdDisabilityLegacy";
import { notification } from "antd";
import { useEffect } from "react";

export const useFetchLookupDisabilityCategory = (setLookupDisabilityCategory: React.Dispatch<React.SetStateAction<LookupListDisabilityCategory[]>>)=> {
    const {axios} = useAuthAxios();
    const {error, setError } = useErrorHandling();

    const fetchFetchLookupDisabilityCategory = async () => {
        if(error){ return new Array<LookupListDisabilityCategory>(); }
        try {
            const endpoint = `nccd/lookup_disabilitiesCategories`;
            const { data } = await axios.get<LookupListDisabilityCategory[]>(endpoint);
            setError(null);
            return data;
        } catch (error) {
            setError(error);
            notification.error({ message: 'Error fetching disability category data!', placement: 'topRight' });
            return new Array<LookupListDisabilityCategory>();
        }
    };
    
    const disabilityCategoryQuery = useQuery('fetchFetchLookupDisabilityCategory', fetchFetchLookupDisabilityCategory, {
        staleTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
        cacheTime: Number(process.env.REACT_APP_QUERY_EXP_SECONDS),
        retry: 1,
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if(!disabilityCategoryQuery.isLoading){
            if(disabilityCategoryQuery.data){
                setLookupDisabilityCategory(disabilityCategoryQuery.data);
            }
        }
    }, [disabilityCategoryQuery.data, disabilityCategoryQuery.isLoading, setLookupDisabilityCategory]);

    return {isLoadingCategory: disabilityCategoryQuery.isLoading };
}