import LookupCheckboxItem from "../Types/LookupCheckboxItem";
import React, { useState } from "react";
import IObjectWithRefId from "../Interface/IObjectWithRefId";
import { LookupCheckboxContext } from "../Context/LookupCheckboxContext";
import { Loading } from "../Components/Layout/Loading";
import { useFetchLookupCheckbox } from "../Hooks/useFetchLookupCheckbox";


export const LookupCheckboxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [lookupCheckboxData, setLookupCheckboxData] = useState<LookupCheckboxItem[]>([]);
    const { isLoading } = useFetchLookupCheckbox(setLookupCheckboxData);

    const getLookupCheckboxById = <T extends IObjectWithRefId>(item: T): string => {
      const result = lookupCheckboxData.find((c: LookupCheckboxItem) => c.publicId.toLowerCase() === item.refId.toLowerCase());
      return result ? (result.hasOther && item.other ? item.other : result.displayValue) : "Not Found";
    };

    const getLookupCheckboxsBySectionId = (sectionId: string): LookupCheckboxItem[] => {
      var checkboxes = new Array<LookupCheckboxItem>();
      checkboxes = lookupCheckboxData.filter((c: LookupCheckboxItem) => c.sectionPublicId.toLowerCase() === sectionId.toLowerCase());
      checkboxes.sort((a, b) => a.displayOrder - b.displayOrder);
      return checkboxes;
    }

    if (isLoading) return <Loading />;
  
    return (
      <LookupCheckboxContext.Provider value={{ lookupCheckboxData, getLookupCheckboxById, getLookupCheckboxsBySectionId}}>
        {children}
      </LookupCheckboxContext.Provider>
    );
  };