import React, { createContext, useContext} from 'react';
import AccessLevelType from '../Enums/AccessLevel';
import PlanType from '../Enums/PlanType';
import { SelectedStudent } from '../Class/SelectedStudent';

interface AppContextType {
    studentPublicId: string;
    setStudentPublicId: React.Dispatch<React.SetStateAction<string>>;
    planType: PlanType;
    setPlanType: React.Dispatch<React.SetStateAction<PlanType>>;
    multiMode: boolean;
    setMultiMode: React.Dispatch<React.SetStateAction<boolean>>;
    accessLevel: AccessLevelType;
    setAccessLevel: React.Dispatch<React.SetStateAction<AccessLevelType>>;
    multiStudentList: SelectedStudent[];
    setMultiStudentList: React.Dispatch<React.SetStateAction<SelectedStudent[]>>;
}

export const AppContext = createContext<AppContextType>({
    studentPublicId: '',
    setStudentPublicId: () => {},
    planType: PlanType.DISABILITY,
    setPlanType: () => {},
    multiMode: false,
    setMultiMode: () => {},
    accessLevel: AccessLevelType.NoAccess,
    setAccessLevel: () => {},
    multiStudentList: [],
    setMultiStudentList: () => {},
});

export const useApp = () => useContext(AppContext);